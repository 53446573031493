'use client';

import { useEffect, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { type IntlRoute, usePathname, useRouter } from '@/i18n/routing';
import { Button } from 'atoms/Button';
import { NavLink } from 'atoms/NavLink';
import { LocaleToggle } from 'atoms/LocaleToggle';
import { NAVIGATION_TYPES, type NavigationTypes } from '@/constants/navigation';
type NavlinkGroupProps = {
  type?: NavigationTypes;
  handleToggleMenu?: () => void;
};
export const NavlinkGroup = ({
  type,
  handleToggleMenu
}: NavlinkGroupProps) => {
  const t = useTranslations('navigation');
  const pathname = usePathname() || '/';
  const route = useRouter();
  const linkRef = useRef<{
    [key: string]: HTMLAnchorElement;
  }>({});
  const [activePathname, setActivePathname] = useState<string | null>(null);
  const [underlinePosition, setUnderlinePosition] = useState(-28);
  const navigation = [{
    name: t('home'),
    slug: '/'
  }, {
    name: t('offer'),
    slug: '/offer'
  }, {
    name: t('projects'),
    slug: {
      pathname: '/projects',
      query: {
        filter: t('categories.kitchens')
      }
    }
  }] as {
    name: string;
    slug: IntlRoute;
  }[];
  const getSlugPathname = (slug: IntlRoute) => typeof slug === 'string' ? slug : slug.pathname || '';
  const handleOpenContact = () => {
    handleToggleMenu && handleToggleMenu();
    route.replace({
      pathname: '/contact'
    });
  };
  const handleActivePathname = (slug: IntlRoute) => {
    setActivePathname(getSlugPathname(slug));
    if (type === NAVIGATION_TYPES.MOBILE && handleToggleMenu) {
      handleToggleMenu();
    }
  };
  useEffect(() => {
    setActivePathname(pathname);
    const setLinkPosition = () => {
      if (activePathname && linkRef.current[activePathname]) {
        const currentTab = linkRef.current[activePathname]?.parentElement;
        if (currentTab) {
          setUnderlinePosition(currentTab.offsetLeft - 28);
        }
      }
    };
    setLinkPosition();
  }, [activePathname, pathname]);
  return <nav className={clsx('static flex w-full max-w-3xl items-center justify-between gap-12', {
    ['h-full flex-col !items-start']: type === NAVIGATION_TYPES.MOBILE
  })} data-sentry-component="NavlinkGroup" data-sentry-source-file="NavlinkGroup.tsx">
			<div className={clsx('relative flex gap-16', {
      ['mt-14 flex-col !gap-11']: type === NAVIGATION_TYPES.MOBILE,
      ['flex-col !gap-4']: type === NAVIGATION_TYPES.FOOTER
    })}>
				{navigation.map((item, index) => <span key={index} className="relative">
						<NavLink className={clsx({
          ['!text-2xl']: type === NAVIGATION_TYPES.MOBILE,
          ['underline hover:text-gray-500']: activePathname === getSlugPathname(item.slug) && type === NAVIGATION_TYPES.MOBILE,
          ['text-base font-light text-white hover:text-gray-300']: type === NAVIGATION_TYPES.FOOTER
        })} ref={(link: HTMLAnchorElement | null) => {
          if (link) {
            linkRef.current[getSlugPathname(item.slug)] = link;
          }
        }} href={item.slug} onClick={() => handleActivePathname(item.slug)}>
							{item.name}
						</NavLink>
					</span>)}

				{activePathname && <div className={clsx('absolute -left-7 top-3 mt-1 h-3 w-14 border-b border-gray-950 transition-all duration-300 ease-out', {
        ['hidden']: type === NAVIGATION_TYPES.MOBILE || !navigation.map(item => getSlugPathname(item.slug) as IntlRoute).includes(activePathname as IntlRoute)
      })} style={{
        left: underlinePosition
      }} />}
			</div>

			{type !== NAVIGATION_TYPES.FOOTER && <div className="flex items-center gap-9">
					<LocaleToggle />

					<Button onClick={handleOpenContact}>{t('contact')}</Button>
				</div>}
		</nav>;
};
NavlinkGroup.displayName = 'NavlinkGroup';