'use client';

import { Suspense, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Hamburger } from 'atoms/Hamburger';
import { Logo } from 'atoms/Logo';
import { NavlinkGroup } from 'molecules/NavlinkGroup';
import { Link } from '@/i18n/routing';
export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => setIsScrolled(document.documentElement.scrollTop > 200));
  }, []);
  return <header className={clsx('fixed z-20 flex w-full justify-center bg-white transition-all duration-300 ease-out', {
    ['shadow-md']: isScrolled
  })} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
			<div className={clsx('mx-auto flex w-full max-w-7xl items-center justify-between px-10 py-7 transition-all duration-300 ease-out max-sm:px-5', {
      ['!py-2']: isScrolled
    })}>
				<div className="flex-2 w-96 min-w-48">
					<Link href={'/'} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
						<Logo size={isScrolled ? 174 : undefined} data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
					</Link>
				</div>

				<div className="static flex w-full justify-end max-lg:hidden">
					<Suspense data-sentry-element="Suspense" data-sentry-source-file="Header.tsx">
						<NavlinkGroup data-sentry-element="NavlinkGroup" data-sentry-source-file="Header.tsx" />
					</Suspense>
				</div>

				<div className="hidden max-lg:flex">
					<Hamburger data-sentry-element="Hamburger" data-sentry-source-file="Header.tsx" />
				</div>
			</div>
		</header>;
};
Header.displayName = 'Header';