import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_dzaonavm5lhpsgypmhr3j6hq74/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_dzaonavm5lhpsgypmhr3j6hq74/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_dzaonavm5lhpsgypmhr3j6hq74/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"]}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/vercel/path0/src/components/atoms/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavlinkGroup"] */ "/vercel/path0/src/components/molecules/NavlinkGroup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/organisms/Header.tsx");
