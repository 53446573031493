import clsx from 'clsx';
type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
  isSmall?: boolean;
  reverseColors?: boolean;
};
export const Button = ({
  children,
  onClick,
  isActive,
  isSmall,
  reverseColors,
  ...props
}: ButtonProps) => <button className={clsx('flex items-center justify-center gap-2 border-2 border-gray-950 px-7 py-4 font-black uppercase transition-colors duration-300 ease-out hover:bg-gray-950 hover:text-white', {
  ['bg-gray-950 text-white']: isActive,
  ['text-xs']: isSmall,
  ['border-white text-white hover:bg-white hover:!text-gray-950']: reverseColors
})} onClick={onClick} {...props} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
		{children}
	</button>;
Button.displayName = 'Button';