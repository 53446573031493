import { forwardRef, type ReactNode, type Ref } from 'react';
import clsx from 'clsx';
import { type IntlRoute, Link } from '@/i18n/routing';
type LinkProps = {
  className?: string;
  children: ReactNode;
  href: IntlRoute;
  ref?: Ref<HTMLAnchorElement>;
  onClick?: () => void;
  onMouseMove?: () => void;
};
export const NavLink = forwardRef<HTMLAnchorElement, LinkProps>(({
  className,
  children,
  href,
  onClick,
  onMouseMove,
  ...props
}, ref) => <Link ref={ref} className={clsx('p-1 text-lg font-medium leading-5 text-gray-950 hover:text-gray-500', className)} href={href} onClick={onClick} onMouseMove={onMouseMove} {...props}>
			{children}
		</Link>);
NavLink.displayName = 'NavLink';