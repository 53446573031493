type LogoProps = {
  size?: number;
  fill?: '#030712' | '#FFFFFF';
};
export const Logo = ({
  size = 184,
  fill = '#030712'
}: LogoProps) => <svg width={size} height="68" viewBox="0 0 184 68" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
		<g clipPath="url(#clip0_16_239)" data-sentry-element="g" data-sentry-source-file="Logo.tsx">
			<path d="M28.494 42.7037V46.5177H13.9424V21.6752H18.5638V42.7037H28.494Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path d="M44.5241 42.9099C45.3832 42.9099 46.1047 42.8068 46.6888 42.6006C47.273 42.3944 47.7368 42.1196 48.0804 41.776C48.4355 41.4324 48.6875 41.0315 48.8364 40.5733C48.9967 40.1152 49.0769 39.6284 49.0769 39.113C49.0769 38.5747 48.991 38.0937 48.8192 37.6699C48.6474 37.2347 48.3782 36.8682 48.0117 36.5704C47.6452 36.2611 47.1699 36.0263 46.5858 35.866C46.0131 35.7056 45.3202 35.6255 44.507 35.6255H39.8511V42.9099H44.5241ZM39.8511 25.2486V32.43H43.5277C45.1083 32.43 46.2994 32.1436 47.1012 31.5709C47.9144 30.9983 48.321 30.0877 48.321 28.8393C48.321 27.5451 47.9544 26.6231 47.2214 26.0733C46.4884 25.5235 45.3431 25.2486 43.7854 25.2486H39.8511ZM43.7854 21.6752C45.4118 21.6752 46.8034 21.8298 47.9602 22.139C49.117 22.4483 50.0619 22.8892 50.7949 23.4619C51.5394 24.0346 52.0834 24.7275 52.427 25.5407C52.7706 26.3539 52.9424 27.2702 52.9424 28.2895C52.9424 28.8737 52.8565 29.4349 52.6847 29.9732C52.5129 30.5 52.2438 30.9983 51.8773 31.4679C51.5222 31.926 51.0641 32.3441 50.5028 32.722C49.9531 33.1 49.2945 33.4207 48.5271 33.6841C51.9402 34.4515 53.6468 36.2955 53.6468 39.2161C53.6468 40.2698 53.4464 41.2434 53.0455 42.1367C52.6446 43.0301 52.0605 43.8032 51.2931 44.4561C50.5257 45.0975 49.5808 45.6014 48.4584 45.9679C47.336 46.3344 46.0532 46.5177 44.61 46.5177H35.2297V21.6752H43.7854Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path d="M98.8414 24.4628V43.7609H96.552V29.5803C96.552 29.3917 96.5565 29.1897 96.5655 28.9742C96.5834 28.7588 96.6014 28.5388 96.6193 28.3144L89.9936 40.3807C89.7871 40.7847 89.4729 40.9867 89.0509 40.9867H88.6739C88.2519 40.9867 87.9377 40.7847 87.7312 40.3807L80.9708 28.2605C81.0246 28.7363 81.0516 29.1762 81.0516 29.5803V43.7609H78.7622V24.4628H80.688C80.9214 24.4628 81.101 24.4853 81.2267 24.5302C81.3523 24.5751 81.4735 24.7007 81.5903 24.9072L88.2564 36.7851C88.3641 37.0005 88.4674 37.225 88.5661 37.4584C88.6739 37.6918 88.7726 37.9297 88.8624 38.1721C88.9522 37.9297 89.0464 37.6918 89.1452 37.4584C89.244 37.216 89.3517 36.9871 89.4684 36.7716L96.0133 24.9072C96.1211 24.7007 96.2378 24.5751 96.3635 24.5302C96.4981 24.4853 96.6822 24.4628 96.9156 24.4628H98.8414Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path d="M118.374 41.6331L118.361 43.7609H106.469V24.4628H118.361V26.5906H109.082V33.0009H116.596V35.0478H109.082V41.6331H118.374Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path d="M131.481 41.687C132.227 41.687 132.868 41.6017 133.407 41.4311C133.946 41.2606 134.386 41.0226 134.727 40.7174C135.077 40.4032 135.333 40.0351 135.495 39.6131C135.656 39.1822 135.737 38.7108 135.737 38.1991C135.737 37.2025 135.382 36.417 134.673 35.8424C133.964 35.2588 132.9 34.967 131.481 34.967H127.307V41.687H131.481ZM127.307 26.5233V33.1086H130.754C131.49 33.1086 132.128 33.0278 132.666 32.8662C133.214 32.7046 133.663 32.4801 134.013 32.1928C134.372 31.9055 134.637 31.5599 134.808 31.1559C134.978 30.7429 135.064 30.294 135.064 29.8092C135.064 28.669 134.722 27.8385 134.04 27.3178C133.358 26.7881 132.298 26.5233 130.862 26.5233H127.307ZM130.862 24.4628C132.047 24.4628 133.066 24.5795 133.919 24.813C134.781 25.0464 135.486 25.3786 136.033 25.8095C136.59 26.2405 136.998 26.7702 137.259 27.3986C137.519 28.0181 137.649 28.7184 137.649 29.4995C137.649 29.9753 137.573 30.4332 137.42 30.8731C137.277 31.304 137.057 31.708 136.76 32.0851C136.464 32.4622 136.087 32.8033 135.629 33.1086C135.18 33.4049 134.651 33.6473 134.04 33.8358C135.459 34.1051 136.522 34.6124 137.232 35.3576C137.95 36.0938 138.309 37.0634 138.309 38.2664C138.309 39.0834 138.156 39.8286 137.851 40.5019C137.555 41.1753 137.115 41.7543 136.531 42.2392C135.957 42.724 135.248 43.101 134.404 43.3704C133.56 43.6307 132.599 43.7609 131.522 43.7609H124.708V24.4628H130.862Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path d="M156.718 41.6331L156.704 43.7609H144.813V24.4628H156.704V26.5906H147.426V33.0009H154.94V35.0478H147.426V41.6331H156.718Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path d="M174 41.5658V43.7609H163.051V24.4628H165.651V41.5658H174Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
			<path fillRule="evenodd" clipRule="evenodd" d="M64.9882 2.39216H1.7724V65.6079H64.9882V2.39216ZM0 0.619751V67.3803H66.7606V0.619751H0Z" fill={fill} data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
		</g>
		<defs data-sentry-element="defs" data-sentry-source-file="Logo.tsx">
			<clipPath id="clip0_16_239" data-sentry-element="clipPath" data-sentry-source-file="Logo.tsx">
				<rect width="184" height="66.7606" fill="white" transform="translate(0 0.619751)" data-sentry-element="rect" data-sentry-source-file="Logo.tsx" />
			</clipPath>
		</defs>
	</svg>;
Logo.displayName = 'Logo';