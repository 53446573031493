'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { Button } from 'atoms/Button';
import { COOKIE_CONSENT } from 'constants/storage';
export const CookieBanner: React.FC = () => {
  const t = useTranslations();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    const cookieConsent = localStorage.getItem(COOKIE_CONSENT);
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);
  const handleAccept = () => {
    localStorage.setItem(COOKIE_CONSENT, 'true');
    setIsVisible(false);
  };
  if (!isVisible) return null;
  return <div className="fixed bottom-6 right-6 z-40 flex w-[500px] items-center justify-center px-10 pb-8 pt-10 max-lg:bottom-0 max-lg:right-0 max-lg:w-screen" data-sentry-component="CookieBanner" data-sentry-source-file="CookieBanner.tsx">
			<div className="absolute h-full w-full bg-gray-950 opacity-80" />

			<div className="z-50 flex flex-col items-center justify-center gap-4">
				<p className="mb-2 text-center text-sm text-white">{t('cookie-consent')}</p>

				<Button onClick={handleAccept} isSmall reverseColors data-sentry-element="Button" data-sentry-source-file="CookieBanner.tsx">
					{t('accept-button')}
				</Button>
			</div>
		</div>;
};