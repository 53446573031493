'use client';

import { memo, useState } from 'react';
import clsx from 'clsx';
import { NavlinkGroup } from 'molecules/NavlinkGroup';
import { Overlay } from 'atoms/Overlay';
import { NAVIGATION_TYPES } from '@/constants/navigation';
const lineStyle = 'ease-in w-full transform border-b-2 border-gray-950 transition duration-300 group-hover:border-gray-500';
export const Hamburger = memo(() => {
  const [isOpen, setOpen] = useState(false);
  const handleToggleMenu = () => setOpen(!isOpen);
  return <div className="hidden max-lg:flex">
			<Overlay onClick={handleToggleMenu} isActive={isOpen} />

			<div className="group z-40 flex w-7 cursor-pointer flex-col gap-2" onClick={handleToggleMenu}>
				<span className={clsx(lineStyle, {
        ['translate-y-2 rotate-45']: isOpen
      })} />

				<span className={clsx(lineStyle, {
        ['opacity-0']: isOpen
      })} />

				<span className={clsx(lineStyle, {
        ['-translate-y-3 -rotate-45']: isOpen
      })} />
			</div>

			<div className={clsx('absolute right-0 top-0 z-30 h-lvh w-9/12 bg-white shadow-lg transition-all duration-300 ease-linear', {
      ['!-right-full']: !isOpen
    })}>
				<div className="h-dvh w-full p-10 transition-all duration-200 ease-linear">
					<NavlinkGroup type={NAVIGATION_TYPES.MOBILE} handleToggleMenu={handleToggleMenu} />
				</div>
			</div>
		</div>;
});
Hamburger.displayName = 'Hamburger';