'use client';

import clsx from 'clsx';
import { memo } from 'react';
type OverlayProps = {
  onClick: () => void;
  isActive: boolean;
};
export const Overlay = memo(({
  onClick,
  isActive
}: OverlayProps) => <div onClick={onClick} className={clsx('duration-400 fixed inset-0 z-30 h-0 bg-slate-800 bg-opacity-50 opacity-0 transition-opacity ease-in', {
  ['!h-lvh opacity-100 backdrop-blur-md']: isActive
})} />);
Overlay.displayName = 'Overlay';